import {Link, graphql, useStaticQuery} from 'gatsby'
import {removeWhitespace} from './../helper-functions/remove-whitespace'
import Container from './container'
import Fade from 'react-reveal/Fade'
import MenuItem from './menu-item'
import React from 'react'
import styled from 'styled-components'
import TelephoneSvg from './telephone-svg'
import {
  HOLDING_PAGE_CSS_MODIFIER,
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_INTERNAL_URL_HARD_RELOAD,
  LINK_TYPE_INTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
} from './../constants/global-constants'

const FOOTER_DESKTOP_LAYOUT_BREAKPOINT = '900px'
const FOOTER_IN_SINGLE_ROW_BREAKPOINT = '1320px'

export default function Footer({pageName, showTheHoldingPage, isIndexPage = false}) {
  const data = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          copyright_text {
            text
          }
          footer_logo {
            alt
            url
          }
          phone_text {
            html
          }
          quick_links_heading {
            text
          }
        }
      }
      prismicContactPage {
        data {
          phone_number {
            text
          }
          email_address {
            text
          }
        }
      }
      prismicSocialMedia {
        data {
          instagram_url {
            text
          }
        }
      }
      prismicShop {
        data {
          show_in_navigation
          link_url {
            url
          }
          navigation_label_text {
            text
          }
        }
      }
      prismicPressPage {
        data {
          show_in_navigation
        }
      }
    }
  `)

  const prismicData = {
    copyrightText: data.prismicFooter.data.copyright_text.text,
    emailAddress: data.prismicContactPage.data.email_address.text,
    footerLogoAlt: data.prismicFooter.data.footer_logo.alt,
    footerLogoHeight: data.prismicFooter.data.footer_logo.dimensions?.height,
    footerLogoUrl: data.prismicFooter.data.footer_logo.url,
    footerLogoWidth: data.prismicFooter.data.footer_logo.dimensions?.width,
    instagramUrl: data.prismicSocialMedia.data.instagram_url.text,
    phoneNumber: data.prismicContactPage.data.phone_number.text,
    phoneTextHtml: data.prismicFooter.data.phone_text.html,
    quickLinksText: data.prismicFooter.data.quick_links_heading.text,
    shopNavEnabled: data.prismicShop.data.show_in_navigation,
    shopNavLabel: data.prismicShop.data.navigation_label_text.text,
    shopNavUrl: data.prismicShop.data.link_url.url,
    pressPageNavEnabled: data.prismicPressPage.data.show_in_navigation,
  }

  const date = new Date()

  return (
    <StyledComponent className={`site-footer site-footer--${showTheHoldingPage ? HOLDING_PAGE_CSS_MODIFIER : pageName}`}>
      <Container type="wide" className="container--footer">
        {showTheHoldingPage ? (
          <div className={`footer-inner footer-inner--${HOLDING_PAGE_CSS_MODIFIER}`}>
            <div className={`phone-wrapper phone-wrapper--${HOLDING_PAGE_CSS_MODIFIER}`}>
              <a className="telephone-link" href={`tel:${removeWhitespace(prismicData.phoneNumber)}`}>
                <TelephoneSvg fill="#fff" className="telephone-svg" />
                <span className="sr-only">Call us</span>
              </a>
              <div className="phone-text" dangerouslySetInnerHTML={{__html: prismicData.phoneTextHtml}} />
            </div>
            <p className={`copyright-text copyright-text--${HOLDING_PAGE_CSS_MODIFIER}`}>{prismicData.copyrightText}</p>
            <img
              className={`footer-logo footer-logo--${HOLDING_PAGE_CSS_MODIFIER}`}
              src={prismicData.footerLogoUrl}
              alt={prismicData.footerLogoAlt}
              width={prismicData.footerLogoWidth}
              height={prismicData.footerLogoHeight}
            />
          </div>
        ) : (
          <>
            <div className={`footer-inner footer-inner--1`}>
              <nav className="footer-nav">
                <Fade>
                  <div>
                    <h3 className="quick-links-heading">{prismicData.quickLinksText}</h3>
                    <ul className="menu-list--footer">
                      <MenuItem
                        label="Home"
                        linkTo={'/'}
                        isSubMenuItem={false}
                        className="home"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL_HARD_RELOAD}
                      />
                      <MenuItem
                        label="Services"
                        linkTo={'/services'}
                        isSubMenuItem={false}
                        className="services"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                      <MenuItem
                        label="Projects"
                        linkTo={'/projects'}
                        isSubMenuItem={false}
                        className="projects"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                      <MenuItem
                        label="E-design"
                        linkTo={'/e-design'}
                        isSubMenuItem={false}
                        className="e-design"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                      {prismicData.shopNavEnabled && (
                        <MenuItem
                          label="Shop"
                          linkTo={'/shop'}
                          isSubMenuItem={false}
                          className="shop"
                          isFooterNav={true}
                          linkType={LINK_TYPE_INTERNAL_URL}
                        />
                      )}
                      <MenuItem
                        label="Studio"
                        linkTo={'/studio'}
                        isSubMenuItem={false}
                        className="studio"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                      {prismicData.pressPageNavEnabled && (
                        <MenuItem
                          label="Press"
                          linkTo={'/press'}
                          isSubMenuItem={false}
                          className="press"
                          isFooterNav={true}
                          linkType={LINK_TYPE_INTERNAL_URL}
                        />
                      )}
                      <MenuItem
                        label="Contact"
                        linkTo={'/contact'}
                        isSubMenuItem={false}
                        className="contact"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                      <MenuItem
                        label="Follow Ali on Insta"
                        linkTo={prismicData.instagramUrl}
                        isSubMenuItem={false}
                        className="instagram"
                        isFooterNav={true}
                        linkType={LINK_TYPE_EXTERNAL_URL}
                      />
                      <MenuItem
                        label="Email us"
                        linkTo={prismicData.emailAddress}
                        isSubMenuItem={false}
                        className="email-us"
                        isFooterNav={true}
                        linkType={LINK_TYPE_EMAIL_LINK}
                      />
                      <MenuItem
                        label="Privacy Policy"
                        linkTo="/privacy-policy"
                        isSubMenuItem={false}
                        className="privacy-policy"
                        isFooterNav={true}
                        linkType={LINK_TYPE_INTERNAL_URL}
                      />
                    </ul>
                  </div>
                </Fade>
              </nav>
              <div className={`phone-wrapper`}>
                <Fade>
                  <a className="telephone-link" href={`tel:${removeWhitespace(prismicData.phoneNumber)}`}>
                    <TelephoneSvg fill="#fff" className="telephone-svg" />
                    <span className="sr-only">Call us</span>
                  </a>
                  <div className="phone-text" dangerouslySetInnerHTML={{__html: prismicData.phoneTextHtml}} />
                </Fade>
              </div>
            </div>
            <div className={`footer-inner footer-inner--2`}>
              <Fade>
                <p className={`copyright-text`}>{prismicData.copyrightText}</p>
              </Fade>
              <Fade>
                <img
                  className={`footer-logo`}
                  src={prismicData.footerLogoUrl}
                  alt={prismicData.footerLogoAlt}
                  width={prismicData.footerLogoWidth}
                  height={prismicData.footerLogoHeight}
                />
              </Fade>
            </div>
          </>
        )}
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.footer`
  background-color: #000;
  color: #fff;
  font-size: 1.4rem;
  padding: 4rem 0;

  .container--footer {
    @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .footer-inner {
    align-items: center;
    display: flex;
    flex-direction: column;

    &--holding-page {
      @media (min-width: 860px) {
        flex-direction: row;
      }
    }

    &--1 {
      margin-bottom: 7rem;

      @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
        align-items: stretch;
        flex-direction: row;
      }

      @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
        margin-bottom: 0;
      }
    }

    &--2 {
      @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
        flex-direction: row;
        margin-top: 3rem;
      }
    }
  }

  .phone-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;

    &--holding-page {
      @media (min-width: 860px) {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 0;
      margin-left: 3rem;
      margin-top: 2.9rem;
    }

    @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
    }
  }

  .telephone-link {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-bottom: 2rem;

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      margin-bottom: 0;
      margin-right: 2rem;
      margin-top: 1rem;
    }
  }
  .telephone-svg {
    width: 3rem;
    height: auto;
  }
  .phone-text {
    margin-bottom: 0;
    text-align: center;
    width: 100%;

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      text-align: left;
    }

    @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
      min-width: 46rem;
    }

    @media (min-width: 1490px) {
      min-width: 53rem;
    }
  }
  .copyright-text {
    flex-shrink: 0;
    margin-bottom: 2rem;

    &--holding-page {
      @media (min-width: 860px) {
        margin-bottom: 0;
        margin-left: 2rem;
      }
    }

    @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
  .footer-logo {
    height: auto;
    width: 8.6rem;

    &--holding-page {
      @media (min-width: 860px) {
        margin-left: 2rem;
      }
    }
  }

  .footer-nav {
    margin-bottom: 2rem;

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 0;
      width: 29rem;
    }

    @media (min-width: 1100px) {
      width: 27.5%;
    }
  }

  .quick-links-heading {
    color: white;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      text-align: left;
    }
  }

  .menu-list--footer {
    margin: 0;
    padding: 0;

    @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
      column-count: 2;
    }

    li {
      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: ${FOOTER_DESKTOP_LAYOUT_BREAKPOINT}) {
        display: block;

        .menu-item-link {
          text-align: left;
          padding-left: 0;
          padding-right: 0;

          @media (min-width: ${FOOTER_IN_SINGLE_ROW_BREAKPOINT}) {
            min-width: 15rem;
          }
        }
      }

      @media (min-width: 1200px) {
        margin-right: 0;
      }
    }
  }
`
