import {Link as ScrollLink} from 'react-scroll'
import {Link} from 'gatsby'
import AnimateHeight from 'react-animate-height'
import React, {useRef} from 'react'
import styled from 'styled-components'
import useOutsideClick from './../hooks/useOutsideClick'
import {
  HOME_PAGE_SCROLL_DOWN_DURATION_MS,
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_INTERNAL_URL_HARD_RELOAD,
  LINK_TYPE_INTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  LINK_TYPE_SUB_NAV_HEADING,
  NAV_MENU_DESKTOP_BREAKPOINT,
  SLIDE_DOWN_SUB_NAV_DURATION_MS,
} from './../constants/global-constants'

export default function MenuItem({
  className,
  clickHandler,
  clickOutsideHandler,
  isExpanded,
  isFooterNav = false,
  isSubMenuItem = false,
  label,
  linkTo,
  linkToClickHandler,
  linkType,
  pageName,
  subMenuConstant,
  subMenuItems,
}) {
  const menuItemRef = useRef()

  useOutsideClick(menuItemRef, () => {
    const isBrowser = typeof window !== `undefined`
    if (isBrowser) {
      const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width
      if (clickOutsideHandler && width >= NAV_MENU_DESKTOP_BREAKPOINT) {
        clickOutsideHandler(subMenuConstant)
      }
    }
  })

  function onMenuItemClick() {
    if (linkToClickHandler) {
      linkToClickHandler(false)
    }
  }

  return (
    <StyledComponent
      className={`menu-item ${isFooterNav ? 'menu-item--footer footer' : ''}`}
      ref={menuItemRef}
      isFooterNav={isFooterNav}
    >
      {linkType === LINK_TYPE_DOWNLOAD_FILE && (
        <a
          className={`menu-item-link menu-item-link--download-file ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {label}
        </a>
      )}
      {linkType === LINK_TYPE_INTERNAL_URL && (
        <Link
          to={linkTo}
          className={`menu-item-link menu-item-link--${className} ${isFooterNav ? 'menu-item-link--footer footer' : ''} ${
            className === pageName ? 'active' : ''
          }`}
          dangerouslySetInnerHTML={{__html: label}}
          onClick={onMenuItemClick}
        />
      )}
      {linkType === LINK_TYPE_INTERNAL_URL_HARD_RELOAD && (
        <a
          className={`menu-item-link menu-item-link--internal-url-hard-reload ${
            isFooterNav ? 'menu-item-link--footer footer' : ''
          }`}
          href={linkTo}
          target="_self"
        >
          {label}
        </a>
      )}
      {linkType === LINK_TYPE_EXTERNAL_URL && (
        <a
          className={`menu-item-link menu-item-link--external-url ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      )}
      {linkType === LINK_TYPE_EMAIL_LINK && (
        <a
          className={`menu-item-link menu-item-link--email-link ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={`mailto:${linkTo}`}
          target="_blank"
        >
          {label}
        </a>
      )}
      {linkType === LINK_TYPE_SCROLL_TO_ANCHOR && (
        <ScrollLink
          href="#"
          to={linkTo}
          smooth={true}
          duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
          className={`menu-item-link menu-item-link--scroll-to-anchor menu-item-link--${className} ${
            isFooterNav ? 'menu-item-link--footer footer' : ''
          }`}
          dangerouslySetInnerHTML={{__html: label}}
          onClick={onMenuItemClick}
          delay={isFooterNav ? 0 : 400}
        />
      )}
      {linkType === LINK_TYPE_SUB_NAV_HEADING && (
        <>
          <button
            type="button"
            className={`menu-item-link menu-item-link--sub-nav-heading menu-item-link--${className} ${
              isFooterNav ? 'menu-item-link--footer footer' : ''
            }`}
            onClick={() => clickHandler(subMenuConstant)}
          >
            <span dangerouslySetInnerHTML={{__html: label}} />
          </button>
          <AnimateHeight
            className="sub-menu-list__animate-height"
            duration={SLIDE_DOWN_SUB_NAV_DURATION_MS}
            height={isExpanded ? 'auto' : 0}
          >
            <ul className={`sub-menu-list sub-menu-list--${className}`}>
              {subMenuItems.map((item, index) => (
                <MenuItem
                  key={`menu-item-${index}`}
                  label={item.label}
                  linkTo={item.linkTo}
                  isSubMenuItem={true}
                  className={item.className}
                />
              ))}
            </ul>
          </AnimateHeight>
        </>
      )}
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => (props.isFooterNav ? '0' : '0')};

  @media (min-height: 667px) {
    margin-bottom: ${props => (props.isFooterNav ? '0' : '0')};
  }

  @media (min-height: 900px) {
    margin-bottom: ${props => (props.isFooterNav ? '0' : '1rem')};
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    margin: 0 1rem 0 0;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 3.5rem;
    }
  }

  @media (min-width: 1250px) {
    margin-right: 1.5rem;
  }

  @media (min-width: 1300px) {
    margin-right: 2.2rem;

    &:last-of-type {
      margin-right: 3.5rem;
    }
  }

  @media (min-width: 1350px) {
    margin-right: 2.4rem;
  }

  @media (min-width: 1400px) {
    margin-right: 2.8rem;
  }

  &--footer {
    margin-bottom: 0;
  }

  .menu-item-link {
    background-color: transparent;
    border: 0;
    color: #000;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.35rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 1rem 0.5rem;
    position: relative;
    text-transform: uppercase;
    transition: color 0.5s linear;

    @media (min-height: 600px) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    @media (min-height: 640px) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    @media (min-height: 760px) {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }

    @media (min-height: 800px) {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }

    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: top left;
      transition: all 0.25s linear;
      width: 100%;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &.active {
      &::after {
        transform: scaleX(1);
      }
    }

    @media (min-width: 1200px) {
      padding: 1.8rem 0.5rem;
    }

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &--footer {
      color: white;
      font-weight: 300;
      padding: 1rem 1rem;
      text-transform: none;

      &:hover {
        color: white;
      }

      &::after {
        display: none;
      }
    }
  }

  .sub-menu-list__animate-height {
    @media (min-width: 1200px) {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 0 0.3rem 0.3rem 0;
    }
  }

  .sub-menu-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 3rem 0 0 0;

    @media (min-width: 1200px) {
      align-items: flex-start;
      background-color: #fff;
      /* box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1); */
      border: 1px solid #cacaca;
      border-top-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      position: relative;
      z-index: 1000;
    }

    .menu-item {
      margin-bottom: 3rem;

      @media (min-width: 1200px) {
        margin: 0 0 1.5rem 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: #6a6a6a;
      font-size: 1.6rem;
      transition: color 0.5s linear;

      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }
`
