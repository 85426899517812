import React from 'react'
import styled from 'styled-components'

export default function Hamburger({navbarOpen, clickHandler}) {
  return (
    <StyledComponent
      className={`hamburger hamburger--squeeze ${navbarOpen ? 'is-active' : ''}`}
      type="button"
      onClick={() => clickHandler(!navbarOpen)}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
      <span className="sr-only">{navbarOpen ? 'Hide navigation' : 'Show navigation'}</span>
    </StyledComponent>
  )
}

const StyledComponent = styled.button`
  background-color: white;
  border-radius: 2rem;
  /* border: 1px solid #666; */
  /* box-shadow: 0px 0px 9px 11px rgba(255, 255, 255, 1); */
  color: #666;
  height: 4rem;
  line-height: 1;
  outline: none;
  padding: 0;
  width: 4rem;

  &.is-active {
    .hamburger-inner {
      background-color: #666;

      &::before,
      &::after {
        background-color: #666;
      }
    }
  }

  .hamburger-inner {
    background-color: #666;

    &::before,
    &::after {
      background-color: #666;
    }
  }
`
