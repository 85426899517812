import React from 'react'

export default function InstagramSvg({fill, className = ''}) {
  return (
    <svg viewBox="0 0 28 28" width="28px" height="28px" fill={fill} className={className}>
      <path d="M14 4.163c3.206 0 3.582.014 4.85.071 1.171.053 1.805.248 2.229.415.562.219.962.476 1.381.895.42.42.682.82.896 1.382.162.424.362 1.057.415 2.23.057 1.266.071 1.643.071 4.849 0 3.206-.014 3.582-.071 4.85-.053 1.171-.248 1.805-.415 2.229-.219.562-.476.962-.896 1.381s-.819.681-1.381.896c-.424.162-1.058.362-2.23.414-1.267.057-1.643.072-4.849.072s-3.582-.015-4.85-.072c-1.171-.052-1.805-.247-2.229-.414a3.726 3.726 0 01-1.382-.896 3.68 3.68 0 01-.895-1.381c-.162-.424-.362-1.058-.415-2.23-.057-1.267-.071-1.643-.071-4.85 0-3.205.014-3.582.071-4.849.053-1.172.248-1.805.415-2.23.219-.561.476-.962.895-1.38.42-.42.82-.682 1.382-.896.424-.162 1.058-.362 2.23-.415 1.267-.062 1.648-.071 4.849-.071zM14 2c-3.258 0-3.668.014-4.95.071-1.276.058-2.148.262-2.91.558a5.85 5.85 0 00-2.125 1.386A5.952 5.952 0 002.63 6.14c-.296.762-.5 1.634-.558 2.915C2.014 10.332 2 10.742 2 14s.014 3.668.071 4.95c.058 1.276.262 2.148.558 2.915a5.85 5.85 0 001.386 2.125 5.952 5.952 0 002.125 1.386c.762.295 1.634.5 2.915.557 1.282.058 1.687.072 4.95.072s3.668-.014 4.95-.072c1.276-.057 2.148-.262 2.915-.557a5.849 5.849 0 002.124-1.386 5.952 5.952 0 001.387-2.125c.295-.762.5-1.634.557-2.915.057-1.282.071-1.687.071-4.95s-.014-3.668-.07-4.95c-.058-1.276-.263-2.148-.558-2.915a5.85 5.85 0 00-1.387-2.125 5.953 5.953 0 00-2.124-1.386c-.762-.295-1.634-.5-2.916-.557C17.668 2.014 17.258 2 14 2z"></path>
      <path d="M14 7.84a6.165 6.165 0 10.002 12.332A6.165 6.165 0 0014 7.84zm0 10.162A4.001 4.001 0 1113.999 10a4.001 4.001 0 010 8.002zm6.407-8.97a1.439 1.439 0 100-2.878 1.439 1.439 0 000 2.878z"></path>
    </svg>
  )
}
