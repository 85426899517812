import {Link, graphql, useStaticQuery} from 'gatsby'
import {RemoveScrollBar} from 'react-remove-scroll-bar'
import AnimateHeight from 'react-animate-height'
import Container from './container'
import Hamburger from './hamburger'
import InstagramSvg from './instagram-svg'
import MenuItem from './menu-item'
import React, {useState} from 'react'
import styled from 'styled-components'
import {
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_INTERNAL_URL_HARD_RELOAD,
  LINK_TYPE_INTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  SLIDE_DOWN_MOBILE_NAV_DURATION_MS,
} from './../constants/global-constants'

export default function Navigation({pageName, showTheHoldingPage, isIndexPage = false}) {
  const [navbarOpen, setNavbarIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      prismicSocialMedia {
        data {
          instagram_url {
            text
          }
        }
      }
      prismicSiteMeta {
        data {
          mobile_navigation_logo {
            alt
            url
          }
          mobile_navigation_footer_text {
            text
          }
        }
      }
      prismicShop {
        data {
          show_in_navigation
          link_url {
            url
          }
          navigation_label_text {
            text
          }
          link_to_shopify
        }
      }
      prismicPressPage {
        data {
          show_in_navigation
        }
      }
    }
  `)

  const prismicData = {
    instagramUrl: data.prismicSocialMedia.data.instagram_url.text,
    mobileNavigationFooterText: data.prismicSiteMeta.data.mobile_navigation_footer_text.text,
    mobileNavigationLogoAlt: data.prismicSiteMeta.data.mobile_navigation_logo.alt,
    mobileNavigationLogoUrl: data.prismicSiteMeta.data.mobile_navigation_logo.url,
    shopNavEnabled: data.prismicShop.data.show_in_navigation,
    shopNavLabel: data.prismicShop.data.navigation_label_text.text,
    shopLinkToShopify: data.prismicShop.data.link_to_shopify,
    shopNavUrl: data.prismicShop.data.link_url.url,
    pressPageNavEnabled: data.prismicPressPage.data.show_in_navigation,
  }

  return (
    <StyledComponent>
      <a
        href={prismicData.instagramUrl}
        target="_blank"
        rel="noopener"
        className="instagram-link instagram-link--mobile-header"
        title="Instagram"
      >
        <span className="sr-only">Instagram</span>
        <InstagramSvg fill="#666" className="instagram-svg" />
      </a>
      <Hamburger className="hamburger" navbarOpen={navbarOpen} clickHandler={setNavbarIsOpen} />
      {navbarOpen && <RemoveScrollBar />}
      <div className={`menu ${navbarOpen ? 'menu--expanded' : ''}`}>
        <AnimateHeight className="animate-height" duration={SLIDE_DOWN_MOBILE_NAV_DURATION_MS} height={navbarOpen ? 'auto' : 0}>
          {prismicData.mobileNavigationLogoUrl && (
            <div className="mobile-menu-logo-wrapper">
              <Link to="/">
                <img
                  className="mobile-menu-logo"
                  src={prismicData.mobileNavigationLogoUrl}
                  alt={prismicData.mobileNavigationLogoAlt}
                />
              </Link>
            </div>
          )}
          {showTheHoldingPage ? (
            <Container className="menu-container">
              <ul className="menu-list">
                <MenuItem
                  label="Contact"
                  linkTo="contact"
                  isSubMenuItem={false}
                  className="contact"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_SCROLL_TO_ANCHOR}
                  pageName={pageName}
                />
                <a
                  href={prismicData.instagramUrl}
                  target="_blank"
                  rel="noopener"
                  className="instagram-link instagram-link--mobile-navigation"
                  title="Instagram"
                >
                  <span className="sr-only">Instagram</span>
                  <InstagramSvg fill="#666" className="instagram-svg" />
                </a>
              </ul>
            </Container>
          ) : (
            <Container className="menu-container">
              <ul className="menu-list">
                <MenuItem
                  label="Home"
                  linkTo={'/'}
                  isSubMenuItem={false}
                  className="home"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL_HARD_RELOAD}
                  pageName={pageName}
                />
                <MenuItem
                  label="Services"
                  linkTo={'/services'}
                  isSubMenuItem={false}
                  className="services"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL}
                  pageName={pageName}
                />
                <MenuItem
                  label="Projects"
                  linkTo={'/projects'}
                  isSubMenuItem={false}
                  className="projects"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL}
                  pageName={pageName}
                />
                <MenuItem
                  label="E-Design"
                  linkTo={'/e-design'}
                  isSubMenuItem={false}
                  className="e-design"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL}
                  pageName={pageName}
                />
                {prismicData.shopNavEnabled && prismicData.shopLinkToShopify && (
                  <MenuItem
                    label={prismicData.shopNavLabel}
                    linkTo={prismicData.shopNavUrl}
                    isSubMenuItem={false}
                    className="shop"
                    linkToClickHandler={setNavbarIsOpen}
                    linkType={LINK_TYPE_EXTERNAL_URL}
                    pageName={pageName}
                  />
                )}
                {prismicData.shopNavEnabled && !prismicData.shopLinkToShopify && (
                  <MenuItem
                    label="Shop"
                    linkTo={'/shop'}
                    isSubMenuItem={false}
                    className="shop"
                    linkToClickHandler={setNavbarIsOpen}
                    linkType={LINK_TYPE_INTERNAL_URL}
                    pageName={pageName}
                  />
                )}
                <MenuItem
                  label="Studio"
                  linkTo={'/studio'}
                  isSubMenuItem={false}
                  className="studio"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL}
                  pageName={pageName}
                />
                {prismicData.pressPageNavEnabled && (
                  <MenuItem
                    label="Press"
                    linkTo={'/press'}
                    isSubMenuItem={false}
                    className="press"
                    linkToClickHandler={setNavbarIsOpen}
                    linkType={LINK_TYPE_INTERNAL_URL}
                    pageName={pageName}
                  />
                )}
                <MenuItem
                  label="Contact"
                  linkTo={'/contact'}
                  isSubMenuItem={false}
                  className="contact"
                  linkToClickHandler={setNavbarIsOpen}
                  linkType={LINK_TYPE_INTERNAL_URL}
                  pageName={pageName}
                />
                <a
                  href={prismicData.instagramUrl}
                  target="_blank"
                  rel="noopener"
                  className="instagram-link instagram-link--mobile-navigation"
                  title="Follow Ali on Instagram"
                >
                  <span className="sr-only">Instagram</span>
                  <InstagramSvg fill="#666" className="instagram-svg" />
                </a>
              </ul>
              {prismicData.mobileNavigationFooterText && (
                <p className="mobile-navigation-footer-text">{prismicData.mobileNavigationFooterText}</p>
              )}
            </Container>
          )}
        </AnimateHeight>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.nav`
  .hamburger {
    position: fixed;
    top: 2.3rem;
    right: 1rem;
    z-index: 4;

    @media (min-width: 375px) {
      top: 2.6rem;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .animate-height {
    @media (min-width: 1200px) {
      height: auto !important;
      overflow: visible !important;
    }

    > div {
      @media (min-width: 1200px) {
        display: block !important;
      }
    }
  }

  .mobile-menu-heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: 700;
    }
  }

  .menu-container {
    padding: 0;
    overflow-y: auto;
    background-color: #fff;
    height: calc(100vh - 18rem);

    @media (min-height: 690px) {
      height: calc(100vh - 21rem);
    }

    @media (min-width: 1200px) {
      height: auto;
      background-color: transparent;
      overflow-y: visible;
    }
  }

  .menu {
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3;

    &--expanded {
      pointer-events: all;
    }

    @media (min-width: 1200px) {
      position: static;
      pointer-events: all;
    }

    h1 {
      background-color: white;
      justify-content: center;
      letter-spacing: 0.3rem;
      margin: 0;
      padding: 7rem 0 0 0;
      text-align: center;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .menu-list {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    pointer-events: all;
    padding: 2rem 0 0 0;

    @media (min-width: 1200px) {
      align-items: flex-start;
      background-color: transparent;
      flex-direction: row;
      height: auto;
      padding: 0;
      overflow-y: visible;
    }
  }

  .instagram-link {
    display: block;
    width: 3.4rem;
    height: 3.4rem;
    transform: translateY(1px);
    margin-bottom: 0;

    @media (min-width: 1200px) {
      height: 3.4rem;
      margin-bottom: 0;
      transform: translateY(0.6rem);
      width: 3.4rem;
    }

    &--mobile-header {
      display: none;
      position: absolute;
      top: 0.7rem;
      right: 5.2rem;

      @media (min-width: 375px) {
        display: block;
      }

      @media (min-width: 400px) {
        right: 7rem;
      }

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .instagram-svg {
    width: 3.4rem;
    height: 3.4rem;
  }

  .mobile-menu-logo-wrapper {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 8rem;
    padding-bottom: 1rem;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .mobile-menu-logo {
    width: 9rem;
    height: auto;

    @media (min-height: 690px) {
      width: 12rem;
    }
  }

  .mobile-navigation-footer-text {
    border-top: 1px solid black;
    display: none;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
    width: 13rem;

    @media (min-height: 667px) {
      display: block;
      margin-top: 3rem;
    }

    @media (min-height: 715px) {
      margin-top: 5rem;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }
`
