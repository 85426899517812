import debounce from 'lodash/debounce'
import {SITE_HEADER_ELEMENT_ID} from './../constants/global-constants'
import MobileDetect from 'mobile-detect'

export default function initViewportHeightCssCustomProperty(
  updateOnResizeMobile = true,
  updateOnResizeDesktop = true,
  updateOnOrientationChange = true
) {
  if (typeof window !== 'undefined') {
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.mobile() && updateOnResizeMobile) {
      window.addEventListener('resize', debounce(setViewportHeightCssCustomProperty, 200))
    } else if (!md.mobile() && updateOnResizeDesktop) {
      window.addEventListener('resize', debounce(setViewportHeightCssCustomProperty, 200))
    }
    if (updateOnOrientationChange) window.addEventListener('orientationchange', debounce(setViewportHeightCssCustomProperty, 200))
  }

  setViewportHeightCssCustomProperty()

  setTimeout(function () {
    setViewportHeightCssCustomProperty()
  }, 500)

  setTimeout(function () {
    setViewportHeightCssCustomProperty()
  }, 2000)
}

function setViewportHeightCssCustomProperty() {
  if (typeof document !== 'undefined') {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
    const siteHeaderEl = document.querySelector(`#${SITE_HEADER_ELEMENT_ID}`)
    if (siteHeaderEl) {
      document.documentElement.style.setProperty('--fixed-header-height', `${siteHeaderEl.offsetHeight}px`)
    }
  }
}
