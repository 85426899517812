import 'focus-visible/dist/focus-visible.js'
import {HOLDING_PAGE_CSS_MODIFIER} from './../constants/global-constants'
import Footer from './footer'
import Header from './header'
import initViewportHeightCssCustomProperty from '../helper-functions/init-viewport-height-css-custom-property'
import React from 'react'
import ScrollToTopBtn from './scroll-to-top-btn'
import SimpleReactLightbox from 'simple-react-lightbox'
import SiteHeadMeta from './site-head-meta'
import styled from 'styled-components'
import whatInput from 'what-input'

import './../styles/styles.scss'

initViewportHeightCssCustomProperty(false, true, true)

export default function Layout({
  children,
  pageName,
  documentTitlePrefix = '',
  showTheHoldingPage,
  isIndexPage = false,
  showScrollToTopBtn = true,
}) {
  return (
    <StyledComponent id="top" className={`site-wrapper page--${showTheHoldingPage ? HOLDING_PAGE_CSS_MODIFIER : pageName}`}>
      <SiteHeadMeta documentTitlePrefix={documentTitlePrefix} isIndexPage={isIndexPage} />
      <Header pageName={pageName} showTheHoldingPage={showTheHoldingPage} isIndexPage={isIndexPage} />
      <SimpleReactLightbox>
        <main className={`site-main site-main--${showTheHoldingPage ? HOLDING_PAGE_CSS_MODIFIER : pageName}`}>
          {children}
          {showScrollToTopBtn && <ScrollToTopBtn />}
        </main>
      </SimpleReactLightbox>
      <Footer pageName={pageName} showTheHoldingPage={showTheHoldingPage} isIndexPage={isIndexPage} />
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  main {
    min-height: calc(100vh - 17.6rem - 81.2rem);

    @media (min-width: 570px) {
      min-height: calc(100vh - 14.6rem - 79.1rem);
    }

    @media (min-width: 1200px) {
      min-height: calc(100vh - 9.15rem - 20.7rem);
    }
  }
`
