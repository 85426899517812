import {Link, graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Navigation from './navigation'
import React from 'react'
import styled from 'styled-components'
import {HOLDING_PAGE_CSS_MODIFIER, SITE_HEADER_ELEMENT_ID, HEADER_LOGO_BASE64_DATA_URI} from './../constants/global-constants'

export default function Header({pageName, showTheHoldingPage, isIndexPage = false}) {
  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          header_logo {
            alt
            url
            dimensions {
              width
              height
            }
          }
          site_title {
            text
          }
        }
      }
      prismicHoldingPage {
        data {
          header_text {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    headerLogoAlt: data.prismicSiteMeta.data.header_logo.alt,
    headerLogoHeight: data.prismicSiteMeta.data.header_logo.dimensions?.height,
    headerLogoUrl: data.prismicSiteMeta.data.header_logo.url,
    headerLogoWidth: data.prismicSiteMeta.data.header_logo.dimensions?.width,
    headerText: data.prismicHoldingPage.data.header_text.text,
    headerText: data.prismicHoldingPage.data.header_text.text,
    siteTitle: data.prismicSiteMeta.data.site_title.text,
  }

  return (
    <StyledComponent
      id={SITE_HEADER_ELEMENT_ID}
      className={`site-header site-header--${showTheHoldingPage ? HOLDING_PAGE_CSS_MODIFIER : pageName}`}
    >
      <div className="header-inner">
        <Container className="header-container" type="wide">
          <a className="header-heading-link" href="/" target="_self">
            {/* <img className="header-logo" src={HEADER_LOGO_BASE64_DATA_URI} alt={prismicData.siteTitle} /> */}
            <img
              className="header-logo"
              src={prismicData.headerLogoUrl}
              alt={prismicData.headerLogoAlt}
              width={prismicData.headerLogoWidth}
              height={prismicData.headerLogoHeight}
              aria-hidden="true"
            />
            <h1 className="header-heading sr-only">{prismicData.siteTitle}</h1>
          </a>
          {showTheHoldingPage && <h2 className="header-holding-text">{prismicData.headerText}</h2>}
          <Navigation showTheHoldingPage={showTheHoldingPage} isIndexPage={isIndexPage} pageName={pageName} />
        </Container>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.header`
  background-color: #fff;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

  /* @media (min-width: 1200px) {
    position: sticky;
    top: 0px;
  } */

  &.site-header--holding-page {
    display: flex;
    flex-direction: column;
  }
  .header-container {
    position: relative;

    @media (min-width: 1200px) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .header-inner {
    padding-top: 2rem;
    padding-bottom: 1.6rem;

    @media (min-width: 1200px) {
      padding-bottom: 2rem;
    }
  }
  .header-heading-link {
    display: inline-block;
    margin-left: -2rem;

    @media (min-width: 1200px) {
      margin-left: -5rem;
    }
  }
  .header-logo {
    height: auto;
    max-width: 25rem;
    width: 100%;

    @media (min-width: 375px) {
      max-width: 28rem;
    }
  }
  .header-holding-text {
    font-size: 1.6rem;
    letter-spacing: 0.6rem;
    line-height: 1.9;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    max-width: 34rem;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 570px) {
      max-width: none;
    }

    @media (min-width: 1200px) {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`
